import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

import { Layout } from '../components/layout';
import { ArticlesList } from '../components/ArticlesList';
import allTopics from '../../contents/topics.json';

const Topic = ({ data, pageContext: { topic } }) => (
  <Layout title={allTopics[topic].title}>
    <section className="hero is-light is-bold">
      <div className="hero-body">
        <div className="container">
          <div className="columns">
            <div className="column is-narrow">
              <Image fixed={data.topicIcon.childImageSharp.fixed} title={allTopics[topic].title} />
            </div>
            <div className="column">
              <h2 className="title">{allTopics[topic].title}</h2>
              <p className="has-text-justified">
                {allTopics[topic].description}
              </p>
              <p className="is-size-7 has-text-right has-text-grey">
                <a href={allTopics[topic].website} target="_blank" rel="noopener noreferrer">{allTopics[topic].website}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ArticlesList edges={data.allMarkdownRemark.edges} />
  </Layout>
);

export const query = graphql`
  query($topic: String!, $topicIcon: String!) {
    allMarkdownRemark(filter: { frontmatter: { topics: { eq: $topic }}}, sort: { fields: [frontmatter___datePublished], order: DESC }) {
      edges {
        node {
          id
          frontmatter {
            title
            author
            datePublished(formatString: "DD MMMM, YYYY")
            topics
            image {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          excerpt(pruneLength: 350)
          fields {
            slug
          }
        }
      }
    }
    topicIcon: file(relativePath: { eq: $topicIcon }) {
      childImageSharp {
        fixed(width: 100, height: 100, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Topic;
